import React from 'react';
import { IntlProvider } from 'react-intl';

import messages_en from '../components/Translations/en.json';
import messages_pt from '../components/Translations/pt.json';
import messages_de from '../components/Translations/de.json';
import messages_fr from '../components/Translations/fr.json';
import messages_es from '../components/Translations/es.json';
import messages_pl from '../components/Translations/pl.json';
import messages_it from '../components/Translations/it.json';

const messagesLang = {
    'en-GB': messages_en,
    'de-DE': messages_de,
    'fr-FR': messages_fr,
    'es-ES': messages_es,
    'pt-PT': messages_pt,
    'pl-PL': messages_pl,
    'it-IT': messages_it
}

const Params = () => {
    let params = new URLSearchParams(window.location.search);
    var lang = params.get('lang');
    let language;
    const supportedLangs = ['en-GB', 'de', 'fr', 'es', 'pt', 'pl', 'it']
    if (lang && supportedLangs.includes(lang)) {
        localStorage.setItem(`locale`, lang);
        language = lang;
    };

    return language
}

const Lang = () => {
    var language = Params();
    if(!language){
        language = navigator.language.split(/[-_]/)[0] === "en" ? "en-GB" : navigator.language.split(/[-_]/)[0] === "pt" ? "pt-PT" : 
        navigator.language.split(/[-_]/)[0] === "fr" ? "fr-FR" : navigator.language.split(/[-_]/)[0] === "es" ? "es-ES" : navigator.language.split(/[-_]/)[0] === "de" ? "de-DE" : 
        navigator.language.split(/[-_]/)[0] === "pl" ? "pl-PL" : navigator.language.split(/[-_]/)[0] === "it" ? "it-IT" : "en-GB"
    }
    return language
}

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.switchToLangEN = () => this.setState({ locale: 'en-GB', messages: messages_en }, () => localStorage.setItem("locale", 'en-GB'));
        this.switchToLangPT = () => this.setState({ locale: 'pt-PT', messages: messages_pt }, () => localStorage.setItem("locale", 'pt-PT'));
        this.switchToLangFR = () => this.setState({ locale: 'fr-FR', messages: messages_fr }, () => localStorage.setItem("locale", 'fr-FR'));
        this.switchToLangES = () => this.setState({ locale: 'es-ES', messages: messages_es }, () => localStorage.setItem("locale", 'es-ES'));
        this.switchToLangDE = () => this.setState({ locale: 'de-DE', messages: messages_de }, () => localStorage.setItem("locale", 'de-DE'));
        this.switchToLangIT = () => this.setState({ locale: 'it-IT', messages: messages_it }, () => localStorage.setItem("locale", 'it-IT'));
        this.switchToLangPL = () => this.setState({ locale: 'pl-PL', messages: messages_pl }, () => localStorage.setItem("locale", 'pl-PL'));
        this.currentProperty = (prop) => this.setState({ currentProperty: prop });
        this.state = {
            locale: Lang(),
            params: Params(),
            messages: messagesLang[Lang()],
            switchToLangEN: this.switchToLangEN,
            switchToLangPT: this.switchToLangPT,
            switchToLangFR: this.switchToLangFR,
            switchToLangES: this.switchToLangES,
            switchToLangDE: this.switchToLangDE,
            switchToLangPL: this.switchToLangPL,
            switchToLangIT: this.switchToLangIT,
            currentProperty: this.currentProperty,
        }
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        return (
            <Context.Provider value={this.state}>
                <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="en">
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext }
